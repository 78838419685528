<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" name="form" :model="formState" @finish="onSearch">
				<a-row>
					<a-form-item label="商品名称" name="mainTitle" class="ui-form__item">
						<a-input v-model:value="formState.mainTitle" placeholder="请输入商品名称" />
					</a-form-item>

					<a-form-item label="商品分类" name="classifyId" class="ui-form__item">
						<a-select style="width: 200px;" v-model:value="formState.classifyId" allow-clear
							placeholder="请选择商品分类">
							<a-select-option v-for="item in classifyList" :key="item.id"
								:value="item.id">{{ item.title }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="所属影院" name="verifyStoreId" class="ui-form__item">
						<a-select style="width: 200px;" v-model:value="formState.verifyStoreId" allow-clear
							placeholder="请选择所属影院">
							<a-select-option v-for="item in cinemaAllList" :key="item.id"
								:value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="商品标签" name="goodsLabelId" class="ui-form__item">
						<a-select style="width: 200px;" v-model:value="formState.goodsLabelId" allow-clear
							placeholder="请选择商品标签">
							<a-select-option v-for="item in labelAllList" :key="item.id"
								:value="item.id">{{ item.title }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="状态" name="isDisabled" class="ui-form__item">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear
							style="width: 190px;">
							<a-select-option :value="0">已启用</a-select-option>
							<a-select-option :value="1">已禁用</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="发货方式" name="deliveryType" class="ui-form__item">
						<a-select v-model:value="formState.deliveryType" placeholder="请选择发货方式" allow-clear
							style="width: 190px;">
							<a-select-option :value="0">无</a-select-option>
							<a-select-option :value="1">快递发货</a-select-option>
							<a-select-option :value="2">到店核销</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['mall_goods_template_add']" type="primary"
							@click="onAdd">新增商品</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button type="primary" html-type="submit">搜索</a-button>
						<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			<a-table style="margin-top: 10px;" :columns="columns" :dataSource="list" rowKey="id"
				:pagination="pagination" :scroll="{ x: 1600 }">
				<template #bodyCell="{ column, record }">
					<template v-if="column.key === 'deliveryType'">
						<div v-if="record.deliveryType === 1">快递发货</div>
						<div v-else-if="record.deliveryType === 2">到店核销</div>
						<div v-else>无</div>
					</template>
					<template v-if="column.key === 'isDisabled'">
						<a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
						<a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
					</template>
					<template v-else-if="column.key === 'action'">
						<a-dropdown :trigger="['click', 'hover']">
							<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
							</a-button>
							<template #overlay>
								<a-menu>
									<div v-permission="['mall_goods_template_edit']" @click="onEdit(record)">
										<a-menu-item>
											编辑
										</a-menu-item>
									</div>
									<div v-permission="['mall_goods_template_copy']" @click="onCopy(record)">
										<a-menu-item>
											复制商品
										</a-menu-item>
									</div>
									<div v-permission="['mall_goods_template_open']" v-if="record.isDisabled === 1"
										@click="onDisabled(record)">
										<a-menu-item>
											启用
										</a-menu-item>
									</div>
									<div v-permission="['mall_goods_template_disabled']" v-else
										@click="onDisabled(record)">
										<a-menu-item>
											禁用
										</a-menu-item>
									</div>
									<div v-permission="['mall_goods_template_delete']" @click="onDelete(record)">
										<a-menu-item>
											删除
										</a-menu-item>
									</div>
								</a-menu>
							</template>
						</a-dropdown>
					</template>
				</template>
			</a-table>
		</a-spin>
	</div>
	<publish v-if="showModal" :isEdit="isEdit" :id="id" :type="type" form="template" @back="onBack"></publish>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import publish from '@/views/mall/goods/publish/publish.vue';
	import {
		getGoodsAllClassifyList
	} from '@/service/modules/mall.js';
	import {
		getGoodsList,
		deleteGoods,
		pullOnShelvesGoods,
		pullOffShelvesGoods
	} from '@/service/modules/goods.js';
	import {
		getCinemaAllList
	} from "../../../../service/modules/cinema";
	import {
		getGoodsLabelSearchList
	} from "../../../../service/modules/mall";
	export default {
		components: {
			Icon,
			publish
		},
		data() {
			return {
				loading: false,
				showModal: false,
				cinemaAllList: [],
				classifyList: [],
				labelAllList: [],
				formState: {},
				searchData: {},
				selectedRowKeys: [],
				list: [],
				columns: [{
					title: '商品名称',
					dataIndex: 'mainTitle'
				}, {
					title: '发货方式',
					key: 'deliveryType'
				}, {
					title: '所属影院',
					dataIndex: 'verifyStoreName'
				}, {
					title: '商品分类',
					dataIndex: 'classifyName'
				}, {
					title: '商品标签',
					dataIndex: 'goodsLabelName'
				}, {
					title: '售价',
					dataIndex: 'nowPrice',
				}, {
					title: '库存',
					dataIndex: 'stock',
				}, {
					title: '销量',
					dataIndex: 'soldNum',
				}, {
					title: '状态',
					key: 'isDisabled',
				}, {
					title: '操作',
					key: 'action',
					width: 150,
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
				id: 0,
				type: 1,
			}
		},
		created() {
			this.getData();
			this.getAllCinema();
			this.getGoodsAllClassifyList();
			this.getGoodsLabelSearchList();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getGoodsList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						saleType: 1,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getAllCinema() {
				this.loading = true;
				try {
					let ret = await getCinemaAllList({});
					this.loading = false;
					if (ret.code === 200) {
						this.cinemaAllList = ret.data;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getGoodsLabelSearchList() {
				this.loading = true;
				try {
					let ret = await getGoodsLabelSearchList({});
					this.loading = false;
					if (ret.code === 200) {
						this.labelAllList = ret.data.list;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getGoodsAllClassifyList() {
				let res = await getGoodsAllClassifyList({
					classifyType: 1
				});
				if (res.code === 200) {
					let classifyList = res.data.filter(item => {
						item.children = res.data.filter(subItem => {
							return subItem.parentId === item.id;
						});
						return !item.parentId
					});
					this.classifyList = classifyList;
				}
			},
			onAdd() {
				this.type = 1;
				this.isEdit = false;
				this.id = 0;
				this.showModal = true;
			},
			onEdit(item) {
				this.type = 1;
				this.isEdit = true;
				this.id = item.id;
				this.showModal = true;
			},
			onCopy(item) {
				this.type = 1;
				this.isEdit = false;
				this.id = item.id;
				this.showModal = true;
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: '确定' + (item.isDisabled ? '启用' : '禁用') + '该商品模板吗？',
					onOk: async () => {
						let ret;
						this.loading = true;
						try {
							if (item.isDisabled) {
								ret = await pullOnShelvesGoods({
									id: item.id
								})
							} else {
								ret = await pullOffShelvesGoods({
									id: item.id
								})
							}
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗?',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await deleteGoods({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功!');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>